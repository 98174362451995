// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import select2 from 'select2';
import 'select2/dist/css/select2.css';
import "@oddcamp/cocoon-vanilla-js";
import * as Turbo from "@hotwired/turbo"
import styles from '../stylesheets/application.sass';
import * as bootstrap from 'bootstrap';
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
require('jquery')
require('bootstrap')
import "trix"
import "@rails/actiontext"

//Rails.start()
ActiveStorage.start()

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
require("trix")
require("@rails/actiontext")


$(document).on("turbo:load", function() {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map((tooltipTriggerEl) => {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});
