import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  constructor() {
    super();
    $("#userForm .file").change(function() {
      $("#userForm .user_photo_visibility").show()
    });
  }
}
