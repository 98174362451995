import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {

  constructor() {
    super();
  }

  connect() {
    $("#commentForm").trigger("reset");
    $(".commentCommentForm").trigger("reset");

    $(".reply-comment").click(function(e) {
      e.preventDefault();
      let id = $(this).parents('.topic-content').attr('id');
      $(".comment-form").hide();
      $("#" + id + " .comment-form").show();
    });

    $(".cancel-comment").click(function() {
      let id = $(this).parents('.topic-content').attr('id');
      $("#" + id + " .comment-form").hide();
    });

    $("#add-image-topic").click(function(e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      $(this).hide();
      $('#commentForm .input-image-topic').show();
      $('#commentForm .input-image-topic input').click();
    });

    $(".add-image-comment").click(function(e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      $(this).hide();
      let id = $(this).parents('.topic-content').attr('id');
      $("#" + id + " .input-image-comment").show();
      $("#" + id + " .input-image-comment input").click();
    })

    $(".comment-submit-button").on('click', function() {
      var button = $(this);
      setTimeout(function() {
        button.attr('disabled', true);
        setTimeout(function() {
          button.attr('disabled', false);
        }, 1000);
      }, 0)
    })
  }
}
