import { Controller } from "stimulus";
import $ from 'jquery';
import select2 from 'select2';
import 'select2/dist/css/select2.css';

export default class extends Controller {
  static targets = [ "select" ]

  constructor() {
    super();
  }

  connect() {
    $('.js-select').select2();
  }
}
