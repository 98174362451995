import { Controller } from "stimulus";
import { Modal } from 'bootstrap';

export default class extends Controller {

  constructor() {
    super();
    this.modal = new Modal(document.getElementById('documentModal'));
  }

  open(e) {
    this.modal.show();
  }

  submit() {
    document.getElementById('documentSubmitButton').click();
  }
}
