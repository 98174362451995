import { Controller } from "stimulus";
import { Modal } from 'bootstrap';
import $ from 'jquery';

export default class extends Controller {
  constructor() {
    super();
    this.onStatusChange = this.onStatusChange.bind();
    this.modal = new Modal(document.getElementById('userModal'))
  }

  onStatusChange(e) {
    $("#searchUsers").submit();
  }

  open(e) {
    this.modal.show();
    setTimeout(function(){
      document.querySelector("#new_user #add_board").click();
      $('#new_user.boards-list .nested-fields:first-child .user_board_users_board_id .select').prop("selectedIndex", 1);
    }, 300);

    $(document.body).on('change','#userForm .boards-list .user_board_users_board_id select',function(){
      let val = $(this).find(":selected").prop('id');
      if(val == 'conseil-d-administration') {
        $(".user_position").show();
      }
    });
  }

  close(e) {
    this.modal.dispose()
  }

  submit() {
    document.getElementById('userSubmitButton').click();
  }

  resend() {
    this.modal.hide();
  }
}
