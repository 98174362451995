import { Controller } from "stimulus";
import { Modal } from 'bootstrap';
import $ from 'jquery';
import select2 from 'select2';
import 'select2/dist/css/select2.css';

export default class extends Controller {
  static targets = [ "select" ]

  constructor() {
    super();
    this.modal = new Modal(document.getElementById('boardModal'))
  }

  open(e) {
    this.modal.show();
  }

  submit() {
    document.getElementById('boardSubmitButton').click();
  }
}
