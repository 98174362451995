import { Controller } from "stimulus";
import { Tooltip } from "bootstrap";
import $ from 'jquery';

export default class extends Controller {

  constructor() {
    super();
  }

  connect() {

    $('.folder-title').click(function(e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      let id = $(this).parents('.folder').attr('id');
      $('#' + id + '> * > .fa-folder-plus').toggle();
      $('#' + id + '> * > .fa-folder-minus').toggle();
      $('#' + id + '> * > .documents-list').toggle();
    })

    if (location.search) {
      setTimeout(function(){
        $('.folder .fa-folder-plus').hide();
        $('.folder .fa-folder-minus').show();
        $('.folder .documents-list').show();
      }, 500);
    }

  }
}
