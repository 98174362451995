import { Controller } from "stimulus";
import { Modal } from 'bootstrap';

export default class extends Controller {

  constructor() {
    super();
    this.modal = new Modal(document.getElementById('documentShowModal'));
    $('#documentShowModal .btn-close').click(function() {
      $(this).parents('.modal').remove();
      $( '.modal-backdrop' ).remove();
      $( 'body' ).removeClass( "modal-open" );
    })
  }

  open(e) {
    this.modal.show();
  }

  close(e) {
    this.modal.dispose()
  }
}
