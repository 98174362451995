import { Controller } from "stimulus";
import { Modal } from 'bootstrap';

export default class extends Controller {
  constructor() {
    super();
    this.modal = new Modal(document.getElementById('userModalShow'))
  }

  open(e) {
    this.modal.show();
  }
}
